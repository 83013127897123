<script setup lang="ts">
/**
 * @author Ia Gugunava
 */
import Error from '../SVG/Error.vue';
import Success from '../SVG/Success.vue';
import Button from '../Button.vue';



withDefaults(
  defineProps<{
      success?: boolean;
      hasClose?: boolean;
  }>(),
  {
    success: false,
    hasClose: false,
  }
);

const emit = defineEmits(["close"]);

const closed = () => {
    emit("close");
}

</script>

<template>
    <div class="response-modal">
        <div class="response-modal__content d-flex al-center j-center dir-col">
            <div v-if="success" class="response-modal__content--image">
                <Success/>
            </div>
            <div v-else class="response-modal__content--image">
                <Error/>
            </div>
            <h2 v-if="success">{{ $t("thank you") }}</h2>
            <h2 v-else>{{ $t("sorry!") }}</h2>
            <p v-if="success">{{ $t("your action was successful") }}</p>
            <p v-else>{{ $t("your action was unsuccessful") }}</p>
        </div>
        <div class="response-modal__button">
            <Button
            @click.native="closed"
            btn-text="done"
            class="back-button"
            :btn-type="1"
            />
        </div>
    </div>
</template>

<style lang="scss">
.response-modal{
    &__content{
        margin-bottom: 40px;
        &--image{
            margin-bottom: 8px;
        }
        h2{
            font-size: 20px;
            line-height: 24px;
            @include weight(700);
            margin-bottom: 8px;
        }
        p{
            font-size: 15px;
            line-height: 20px;
            @include weight(400);
        }
    }
    &__button{
        .back-button{
            width: 100%;
        }
    }
}
</style>